<template>
    <section class="content">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Add Daily Power Devotional</h4>
                <h6 class="card-subtitle">A comprehensive daily devotional guide</h6>
                <form @submit.prevent="save">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Date</label>                                
                                <datepicker v-model="lesson.lessondate"
                                    :format="`YYYY-MM-DD`"
                                    :inline="true" 
                                    :bootstrap-styling="true"
                                    :typeable="true"
                                    :disabled-dates="disabledDates" />
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="">Title</label>
                                <input type="text" class="form-control" required v-model="lesson.title">
                            </div>
                            <div class="form-group">
                                <label for="">Reference Text</label>
                                <input type="text" class="form-control" required v-model="lesson.reference">
                            </div>
                            <div class="form-group">
                                <label for="">Content</label>
                                <TextareaAutosize
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.content"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            <div class="form-group">
                                <label for="">Prayer and Confession</label>
                                <TextareaAutosize
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.action"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>

                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isThought">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Thoughts</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isThought"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.thought"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isSoWhat">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">So What?</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isSoWhat"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.sowhat"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isQuestion">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Question</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isQuestion"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.question"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            <!-- <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isPrayer">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Prayer</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isPrayer"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.prayer"
                                    :min-height="30"
                                    :max-height="350" />
                            </div> -->

                            <button class="btn btn-success" :disabled="isProcessing">
                                <span>Save Lesson</span>
                                <loader v-if="isProcessing" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <pre>{{ disabledDates.dates }}</pre> -->
    </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
    data(){
        return {
            year: this.$store.state.devotionalYear,
            lesson: {
                lessondate: this.$store.state.defaultLessonDate,
                title: "",
                reference: "",
                content: "",
                action: "",
                isThought: false,
                isPrayer: false,
                isSoWhat: false,
                isQuestion: false,
                thought: "",
                question: "",
                prayer: "",
                sowhat: "",
            },
            isProcessing: false,
            disabledDates: {
                dates: []
            },
        }
    },
    created(){
        this.disabledDateArray()
    },
    methods: {

        async save(){
            this.lesson.date = this.$moment(this.lesson.lessondate).format("YYYY-MM-DD")
            this.isProcessing = true
            const addDailyPower = this.$fn.httpsCallable("addDailyPower")
            await addDailyPower(this.lesson) // const record = 
            
            // display success message
            this.$toast.success("Lesson added successfully")
            
            this.disabledDates.dates.push(this.lesson.lessondate)
            this.lesson = {
                lessondate: new Date(this.$moment(this.lesson.lessondate).add(1, "days")),
                title: "",
                reference: "",
                content: "",
                action: "",
            }
            this.isProcessing = false
        },

        async disabledDateArray(){
            let dateArray = []
            const doc = await this.$db.collection("devotionals")
                .doc("dailypower")
                .collection("year")
                .doc(this.year)
                .get()
            if (doc.exists) {
                dateArray = doc.data().addedPosts
                dateArray.map(d => {
                    this.disabledDates.dates.push(d.toDate())
                })
                
                const lastDate = this.disabledDates.dates.pop()
                this.disabledDates.dates.push(lastDate)
    
                const nextDate = new Date(this.$moment(lastDate).add(1, "days"))
                this.lesson.lessondate = nextDate
            }
        },

    },
    components:{
        Datepicker,
        "loader": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>